export default class Vector2 {
  static fromAngle(angle: number) {
    return new Vector2(Math.cos(angle), Math.sin(angle));
  }

  constructor(public x: number, public y: number) {
  }

  add(other: Vector2) {
    return new Vector2(this.x + other.x, this.y + other.y);
  }

  sub(other: Vector2) {
    return new Vector2(this.x - other.x, this.y - other.y);
  }

  mul(scalar: number) {
    return new Vector2(this.x * scalar, this.y * scalar);
  }

  div(scalar: number) {
    return new Vector2(this.x / scalar, this.y / scalar);
  }

  angleBetween(other: Vector2) {
    return Math.atan2(other.y - this.y, other.x - this.x);
  }

  distance(other: Vector2) {
    const dx = this.x - other.x;
    const dy = this.y - other.y;

    return Math.sqrt(dx * dx + dy * dy);
  }
}
