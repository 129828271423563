import React, { FC, memo } from "react";
import styles from "./Section.module.scss";
import classnames from "classnames";
import Tween from "../Tween/Tween";
import { useInView } from "@react-spring/web";

export interface SectionProps {
  className?: string;
  category: string;
  title: string;
  description: string;
  theme?: "white";
}

const Section: FC<SectionProps> = memo(
  ({ className, category, title, description, theme }) => {
    const [ref, inView] = useInView({
      once: true,
      amount: .8,
    });

    return (
      <div
        ref={ref}
        className={classnames(
          styles.section,
          className,
          theme && styles[theme]
        )}
      >
        <Tween visible={inView}>
          <h2 className={styles.category}>{category}</h2>
        </Tween>
        <Tween visible={inView}>
          <h1 className={styles.title}>{title}</h1>
        </Tween>
        <Tween visible={inView}>
          <p className={styles.description}>{description}</p>
        </Tween>
      </div>
    );
  }
);

export default Section;
