import React, { FC, memo, ReactElement, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

export interface TransitionProps {
  visible?: boolean;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
  delay?: number;
  timeout?: number;
  children: ReactElement;
}

const Tween: FC<TransitionProps> = memo(
  ({ visible: visibleProp, delay = 0, timeout = 1000, mountOnEnter = false, unmountOnExit = false, children }) => {
    const ref = useRef<HTMLElement | null>(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
      if (visibleProp !== undefined) {
        return;
      }

      if (delay === 0) {
        setVisible(true);
      } else {
        const timeout = setTimeout(() => {
          setVisible(true);
        }, delay);

        return () => {
          clearTimeout(timeout);
        };
      }
    }, []);

    return (
      <CSSTransition
        nodeRef={ref}
        in={visibleProp === undefined ? visible : visibleProp}
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
        timeout={timeout}
      >
        {React.cloneElement(children, { ref })}
      </CSSTransition>
    );
  }
);

export default Tween;
