import React, { FC, memo } from "react";
import styles from "~/components/App/App.module.scss";
import Home from '../Home/Home';
import { Routes, Route } from 'react-router-dom';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import CommunityGuidelines from '../CommunityGuidelines/CommunityGuidelines';

export interface AppProps {}

const App: FC<AppProps> = memo(() => {
  return (
    <div className={styles.app}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/community-guidelines" element={<CommunityGuidelines />} />
      </Routes>
    </div>
  );
});

export default App;
