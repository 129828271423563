import React, { FC, memo } from "react";
import styles from "./DownloadModal.module.scss";
import Tween from '../Tween/Tween';

export interface DownloadModalProps {
  visible: boolean;
  onClose?: () => void;
}

const DownloadModal: FC<DownloadModalProps> = memo(({ visible, onClose }) => {
  return (
    <Tween visible={visible} unmountOnExit>
      <div className={styles.downloadModal}>
        <div className={styles.graphics} />
        <div className={styles.content}>
          <h1>COMING SOON!</h1>
          <div className={styles.icon} />
          <p>
            Boppin will be available
            <br />
            as a mobile app
          </p>
        </div>
        <button
          className={styles.closeButton}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
      </div>
    </Tween>
  );
});

export default DownloadModal;
