import Entity from './Entity';
import Vector2 from './Vector2';

const MAX_DISTANCE = 0.5;

const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

export default class EasterEggText extends Entity {
  position = new Vector2(0.5, 0.5);
  velocity = new Vector2((Math.random() - 0.5) * 1.5, Math.random() * -1.0);

  update(delta: number) {
    this.position = this.position.add(this.velocity.mul(delta));
    this.velocity.y += 0.98 * delta;
  }

  render(context: CanvasRenderingContext2D) {
    const { width, height } = context.canvas;
    const distance = this.position.distance(new Vector2(0.5, 0.5));
    const opacity = clamp((MAX_DISTANCE - distance) / MAX_DISTANCE, 0, 1);

    const fontSize = width / 20;

    context.globalAlpha = opacity;
    context.fillStyle = '#ffffff';
    context.font = `${fontSize}px Poppins`;
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.fillText('Kyo181', this.position.x * width, this.position.y * height);
  }
}
