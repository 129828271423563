import React, { FC, memo } from "react";
import styles from "./PrivacyPolicy.module.scss";

export interface PrivacyPolicyProps {}

const PrivacyPolicy: FC<PrivacyPolicyProps> = memo(() => {
  return (
    <div
      className={styles.privacyPolicy}
      dangerouslySetInnerHTML={{
        __html: `Boppin Privacy Policy
We, WEAR Co., Ltd (hereinafter referred to as "the Company" or "Boppin"), prioritize the protection of your personal information. The protection of your personal information is a core value that determines the framework and direction of the services and products we provide, and we make every effort to ensure that you can use all the services we provide.

In this Privacy Policy, we will inform you of why and how we use the personal information provided by you, and which measures are taken to protect your personal information.

This Privacy Policy applies to Boppin and all the websites, apps, events, and other services operated by Boppin and WEAR Co., Ltd., which are all referred to as "Service(s)" for ease of understanding. However, for some Services, a separate privacy policy may be required, and in such cases, the privacy policy of the relevant Service, not this Privacy Policy, will apply.

We are committed to protecting the personal information and rights of data subjects in accordance with relevant laws and regulations, such as the Personal Information Protection Act and the Act on Promotion of Information and Communications Network Utilization and Information Protection, etc. To promptly and smoothly handle related complaints, we establish and disclose this Privacy Policy as follows:

○ This Privacy Policy is effective as of April 21, 2023.

<b>Article 1 Purpose of Processing Personal Information</b>

The Company processes personal information for the following purposes. The personal information processed will not be used for any purpose other than the following purposes, and if the purpose of use is changed, the Company will implement necessary measures in accordance with the Personal Information Protection Act, such as obtaining separate consent:

Membership registration and management

Personal information is processed for the purpose of confirming a member's intention to join, identifying and authenticating the person for the provision of membership services, maintaining and managing membership, preventing unauthorized use of Services, providing various notices and notifications, and handling grievances.

Provision of goods or services

Personal information is processed for the purpose of providing services and contents.

Utilization for marketing and advertising

Personal information is processed for the purpose of providing information about events and advertisements and providing opportunities to participate in events.

Grievance handling

Personal information is processed for the purpose of identifying the identity of a complainant, verifying the complaint, contacting and notifying the complainant for fact-finding, and notifying the outcome of the complaint process.

<b>Article 2 Period of Processing and Retention of Personal Information</b>

① The Company shall process and retain personal information within the period of retention and use of personal information agreed upon when collecting personal information from the data subject or as required by applicable laws and regulations.

② The period of processing and retention of personal information for each purpose is as follows:

Service Membership Registration and Management: Until withdrawal from the membership

However, in cases where the following reasons apply, personal information will be processed and retained until the end of the reason:

If an investigation or inquiry is being conducted due to a violation of relevant laws and regulations, until the end of such investigation or inquiry.
If the legal relationship resulting from the use of the Service continues to exist, until the end of such legal relationship.

Provision of goods or services: Until the final provision of goods or services.

However, in cases where the following reasons apply, personal information will be processed and retained until the end of the applicable period:

Records related to indication/advertisement pursuant to the Act on the Consumer Protection in Electronic Commerce and records related to transactions such as contents and implementation of a contract.
Records on indication/advertisement: 6 months
Records on contract or supply of goods or services: 5 years
Records on consumer complaints or dispute resolution: 3 years
For other records or materials as required by applicable laws and regulations, until the end of the applicable period prescribed by such laws and regulations.

<b>Article 3 Items of Personal Information Processed</b>

① The Company processes the following personal information items

Membership registration and management
Required items: date of birth, nickname
Optional items: mobile phone number, email address, and the following items:
If you sign up or link your account with Google: Social media account information, including name, profile picture, and email address.
If you sign up or link your account with Apple: Social media account information, including name and email address.
If you sign up or link your account with Kakao: Social media account information, including name, profile picture, email address, and age.
In connection with the provision of goods and services, the Company processes the following personal information items:
If you use the photo addition feature when creating a post, we request camera and gallery access on your device.
Nickname, profile picture, gender, status update history, language used, login history, content posted, notification history and content, and activity history, including activity time, activity frequency and duration, are collected as required.
When you log out, we ask for your Google, Apple, and Kakao account information so that you don’t have any inconvenience when you log back in.
During events/promotions, additional personal information may be collected from users of such services, and if additional personal information is collected, we will inform you of the 'items of personal information to be collected, the purpose of collecting and using personal information, and the retention period of personal information' at the time of collection and obtain your consent.
For Service use inquiries, email address and login account are collected mandatorily, and mobile phone number is collected optionally to receive and resolve the inquiries. In the case of receiving a report of infringement of rights, the name, date of birth, mobile phone number, email address, and identification data (the last 7 digits of the resident registration number and the address are redacted) are required to receive and resolve the infringement case, and in the case of receiving the report from a representative submitting a power of attorney, the name and date of birth of the representative are also required.
During the service registration process, a unique device ID may be issued and stored for each device or browser, and user activity data, device information (operating system, hardware and software version, available storage space, browser type, device identifier, etc.), access IP information, access logs, ADID or IDFA, date and time of visit, service use history, sanction and suspension history, information uploaded by the user, and cookies may be collected in the course of service use or business operations.

<b>Article 4 Disclosure of Personal Information</b>

① The Company will only use your personal information within the scope notified about the purpose of processing and the collection of your personal information.

② The Company does not sell your personal information to advertisers and will never share information that can identify you (name, email address, or other contact information) unless you specifically give us permission to do so.

③ The Company also does not provide personal information to third parties without your prior consent, except in the following cases:

Only when you expressed your consent in order to use the services of a third party affiliated with Boppin, your personal information (profile picture, name, email address) may be provided for the purpose of service operation and development or customer consultation;
The contents of the personal information to be provided under Article 4, Paragraph 3, Subparagraph 1 may be added or changed in the course of service provisions, and if the personal information required to use the affiliated service is changed, we will obtain your additional consent when you use the service;
In addition, if permitted by the Personal Information Protection Act, personal information may be provided accordingly.

<b>Article 5 Provision of Personal Information to Third Parties</b>

① In order to provide better services, the Company will provide personal information only within the minimum necessary scope with your consent in the following cases:

<table>
<tr>
<th>
Recipient
</th>
<th>
Purpose of provision
</th>
<th>
Items provided
</th>
<th>
Period of retention and use
</th>
</tr>
<tr>
<td>N/A</td>
<td>-</td>
<td>-</td>
<td>-</td>
<td>-</td>
</tr>
</table>

<table>
<tr>
<th>
Contractor (trustee)
</th>
<th>
Outsourced work
</th>
<th>
Period of retention 
and use
</th>
</tr>
<tr>
<td>
11 번가(주)
</td>
<td>
Sending messages 
(SMS/LMS/MMS)
</td>
<td>
Until the purpose of 
processing personal 
information is fulfilled
</td>
</tr>
<tr>
<td>
(주)알리는사람들
</td>
<td>
Sending messages 
(SMS/LMS/MMS)
</td>
<td>
Until the purpose of 
processing personal 
information is fulfilled
</td>
</tr>
</table>


<b>Article 6 Outsourcing of Personal Information Processing</b>

① The Company outsources the processing of personal information as follows for an effective processing of personal information:

Contractor (trustee) / Outsourced work / Period of retention and use

② When entering into an outsourcing contract, the Company specifies in documents such as contracts the prohibition of processing personal information other than for the purpose of performing outsourced work, technical and administrative protection measures, restrictions on re-outsourcing, management and supervision of the contractor, and responsibilities such as liabilities for damages, in accordance with Article 26 of the Personal Information Protection Act, and supervises whether the contractor processes personal information securely.
③ If there is any change in the contents of the outsourced work or the contractor, the Company will disclose such change in this Privacy Policy without delay.

<b>Article 7 Overseas Transfer of Personal Information</b>

① The Company provides Boppin Services overseas and outsources related work to overseas corporations as follows:

<table>
<tr>
<th>
Contractor
</th>
<th>
Contractor’s Location
</th>
<th>
Date and method of outsourcing
</th>
<th>
Contact information of the data protection manager
</th>
<th>
Outsourced personal information items
</th>
<th>
Details of outsourcing
</th>
<th>
Period of retention and use of personal information
</th>
</tr>
<tr>
<td>
Amazon
Web
Services,
Inc.
</td>
<td>
The U.S. 
</td>
<td>
Real-time
transmission
</td>
<td>
aws-korea-
privacy@amazon.com
</td>
<td>
All data
</td>
<td>
Data storage and
service provision
</td>
<td>
Life of the
user account
</td>
</tr>

<tr>
<td>
Twilio Inc
</td>
<td>
The U.S. 
</td>
<td>
Third-Party
Disclosure-
</td>
<td>
privacy@twilio.com
</td>
<td>
Mobile phone
number of the
recipient
</td>
<td>
Sending messages
(SMS/LMS/MMS)
</td>
<td>
Until the
purpose of
processing
personal
information is
fulfilled
</td>
</tr>

<tr>
<td>
WhatsApp
LLC
</td>
<td>
The U.S. 
</td>
<td>
Third-Party
Disclosure-
</td>
<td>
support@whatsapp.com
</td>
<td>
Mobile phone
number of the
recipient
</td>
<td>
Sending messages
(SMS/LMS/MMS)
</td>
<td>
Until the
purpose of
processing
personal
information is
fulfilled
</td>
</tr>
</table>

<b>Article 8 Personal Information Destruction Procedure and Method</b>

① The Company will promptly destroy the personal information that is no longer needed, such as when the retention period has expired or the purpose of collecting the personal information is achieved.

② If the retention period that you agreed to has expired or the purpose of processing has been achieved but the personal information should be retained in accordance with relevant laws and regulations, the Company transfers such personal information to a separate database (DB) or a different storage location.

③ The procedures and methods of destroying personal information are as follows:

Destruction Procedure

The Company selects the personal information for which the cause for destruction has occurred and destroys the personal information with the approval of the personal information protection manager of the Company.

Destruction Method

The Company destroys personal information in the form of electronic files with technical methods so that restoration is not possible, and destroys personal information printed, recorded, and saved in paper documents by shredding or incinerating them.

<b>Article 9 Measures for the Destruction of Personal Information of Non-Users</b>

The Company turns the accounts of users who did not use Services for 1 year into dormant accounts and stores their personal information separately. Such personal information will be destroyed without delay after being stored for one year.

The Company notifies members whose account is about to become dormant of a plan to store their personal information separately, the scheduled date of transition to dormancy, and the personal information items that will be stored separately, through a method that can be notified to the member, such as email, 30 days before the transition to dormancy.

If you do not want your account to be turned to dormancy, you should log in to the Service before the transition. In addition, if you log in even after the transition, you can restore the dormant account with your consent and use the service normally.

<b>Article 10 Rights and Obligations of Data Subjects and Their Legal Representatives, and Methods of Exercising the Rights</b>

You may exercise the rights against the Company at any time to demand access to and correction and deletion of your personal information and suspension of processing thereof.

The exercise of the rights under Article 10, Paragraph 1 shall be made in writing, by email, or other means to the Company in accordance with Article 41(1) of the Enforcement Decree of the Personal Information Protection Act, and the Company shall promptly take necessary measures.

The exercise of the rights under Article 10, Paragraph 1 may also be made by the member's legal representative or agent who has been delegated. In this case, a power of attorney in accordance with the form of Appendix 11 to the "Guidelines for the Processing of Personal Information" (No. 2020-7) must be submitted.


The right to demand access to your personal information and suspension of processing thereof may be restricted under Article 35(4) and Article 37(2) of the Personal Information Protection Act.

The right to demand correction and deletion of your personal information cannot be exercised if laws and regulations specifiy the personal information to be collected.

The Company shall verify whether the person who demands access to and correction and deletion of his/her personal information, and suspension of processing thereof   in accordance with a member's rights is the member or a his/her authorized representative.

<b>Article 11 Measures to Ensure the Protection of Personal Information</b>

The Company takes the following measures to ensure the protection of personal information:

Administrative measures: Establishment and implementation of internal management plans, operation of dedicated organizations, and regular employee training

Technical measures: Management of access rights to personal information processing systems, installation of access control systems, encryption of personal information, installation and update of security programs

Physical measures: Access control to computer rooms, data storage rooms, etc.

<b>Article 12 Installation and Operation of Devices That Automatically Collect Personal Information and Refusal of Such Devices</b>

① The Company uses "cookies" that help store and retrieve member’s visit information in order to provide personalized services to its members.

② A cookie is a small amount of information sent from a server (http) that operates a website to a member's browser and may be stored on a member's device.
Purpose of using cookies: To provide members with optimized information by understanding the Services that members visited, the patterns of using and visiting websites, popular search terms, and secure login status, etc.
Installation, operation, and refusal of cookies: Members can refuse to store cookies through the option settings in the tool> internet options> privacy menu located at the top of their web browser.
If a member refuses to store cookies, there may be difficulties in using personalized Services.

<b>Article 13 Criteria for Additional Use and Provision</b>

① The Company may additionally use and provide personal information without your consent in consideration of the matters set forth in Article 14(2) of the Enforcement Decree of the Personal Information Protection Act in accordance with Articles 15(3) and 17(4) of the Personal Information Protection Act.

② Accordingly, in order for the Company to additionally use and provide personal information without your consent, the following matters have been considered:
Whether it is reasonably related to the original purpose for which the personal information was collected; Whether additional use or provision of personal information is foreseeable in light of the circumstances under which the personal information was collected and processing practices; Whether additional use and provision of personal information unfairly infringes on your interests; Whether the measures required to ensure security such as pseudonymization or encryption have been taken.

<b>Article 14. Matters Concerning Personal Information Protection Manager</b>

① The Company has designated a Personal Information Protection Manager as follows to take responsibility for the overall management of personal information and handle complaints and remedies related to the processing of personal information:
▶Personal Information Protection Manager
• Name: Chanhee Kim
• Position: CEO
• Department: Data Privacy and Security Division
• Contact: official@boppin.app

② You can contact the Personal Information Protection Manager or relevant department for all inquiries, complaints, and remedies related to personal information protection that may arise while using the Company's Services. The Company will promptly respond and handle members' inquiries.

<b>Article 15 Department in Charge of Receiving and Processing Requests for Access to Personal Information</b>

You may request to permit access to your personal information under Article 35 of the Personal Information Protection Act by contacting the following department. The Company will make every effort to process the request promptly.

▶ Department in charge of processing requests for access to personal information

Department Name: Data Privacy and Security Division
Contact: support@boppin.app

<b>Article 16 Remedies for Infringement of Data Subject's Rights and Interests</b>

① You may seek dispute resolution or counseling on personal information infringement from the Korea Internet & Security Agency's Personal Information Infringement Report Center, the Korea Communications Commission's Personal Information Dispute Mediation Committee, or other organizations. If you need to report or consult about other personal information infringements, please contact the following organizations:

Personal Information Infringement Report Center (http://privacy.kisa.or.kr / 118 without an area code)
Personal Information Dispute Mediation Committee (http://www.kopico.go.kr / 1833-6972 without an area code)
Supreme Prosecutors' Office (http://www.spo.go.kr / 1301 without an area code)
National Police Agency (http://ecrm.cyber.go.kr / 182 without an area code)

② We acknowledge your right to decide on your own personal information and make every effort to provide counseling and remedies for personal information infringement. If you need to report or consult about personal information infringement, please contact the following department:
▶ Customer Counseling and Reporting Department for Personal Information Protection

Department Name: Data Privacy and Security Division
Contact: support@boppin.app

<b>Article 17 Installation and Operation of Video Processing Equipment</b>

We have installed and are operating video processing equipment as follows:

Ground and purpose of the installation: Safety of facilities and fire prevention.
Number of installations, locations, and scope of recording: 7 units installed in major facilities such as buildings and parking lots; scope of recording includes the entire space of the entrances to major facilities.
The person and department in charge with access to video information: Kim Chan-hee
Recording time, retention period, storage location, and processing method of video information:
Recording time: 24 hours
Retention period: 14 days from the time of recording
Storage location and processing method: Stored and processed in the building's video processing equipment controlling room.
Method and location of accessing video information: Request to the building's manager responsible for the management.
Actions taken in response to data subjects’ request for access: A request for access to personal video information or a request for confirmation of existence of personal video information shall be made in writing using the prescribed form. The access is permitted only in cases where the data subject is clearly identifiable in the video or when it is necessary to protect the data subject's life, body, or property.
Technical, administrative, and physical measures to protect video information: Establishment of an internal management plan, access control and access restriction, application of secure storage and transmission technology for video information, establishment of measures on the storage of processing records and prevention of forgery, provision of storage facilities, and installation of locks, etc.

Download the Privacy Policy <a href="https://boppin-file.s3.ap-northeast-2.amazonaws.com/docx/privacy-policy.docx" target="_blank">(click)</a>

<b>Article 18 Matters Concerning Changes to the Privacy Policy</b>

① This Privacy Policy will be effective from April 21, 2023.
② The previous Privacy Policy can be found below:
`,
      }}
    ></div>
  );
});

export default PrivacyPolicy;
