import React, { FC, memo, useCallback, useState } from "react";
import styles from "./MobileGnb.module.scss";
import classnames from "classnames";
import Tween from "../Tween/Tween";

export interface MobileGnbProps {}

const MobileGnb: FC<MobileGnbProps> = memo(() => {
  const [open, setOpen] = useState(false);

  const closeGnb = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div
      className={classnames(styles.mobileGnb, {
        [styles.open]: open,
      })}
    >
      <div
        className={styles.icon}
        onClick={() => {
          setOpen((prevState) => !prevState);
        }}
      >
        <div className={styles.divider} />
        <div className={styles.divider} />
        <div className={styles.divider} />
      </div>
      <Tween visible={open} unmountOnExit mountOnEnter timeout={300}>
        <div className={styles.gnb}>
          <div className={styles.gnbLinks}>
            <a href="#avatar" onClick={closeGnb}>
              Avatar
            </a>
            <a href="#friends" onClick={closeGnb}>
              Friends
            </a>
            <a href="#my-room" onClick={closeGnb}>
              My Room
            </a>
          </div>
          <div className={styles.socialLinks}>
            <a
              href="https://www.instagram.com/boppin_global/"
              target="_blank"
              className={classnames(styles.social, styles.instagram)}
            >
              instagram
            </a>
            <a
              href="https://twitter.com/Boppin_official"
              target="_blank"
              className={classnames(styles.social, styles.twitter)}
            >
              twitter
            </a>
          </div>
          <div className={styles.copyright}>
            © 2023 WE-AR Inc. All rights reserved.
          </div>
        </div>
      </Tween>
    </div>
  );
});

export default MobileGnb;
