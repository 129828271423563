import React, { FC, memo } from "react";
import styles from "./CommunityGuidelines.module.scss";

export interface CommunityGuidelinesProps {}

const CommunityGuidelines: FC<CommunityGuidelinesProps> = memo(() => {
  return (
    <div className={styles.communityGuidelines}>
      {`## Community Guidelines

Let’s bop! ‘Boppin’ is a medium to communicate with diverse people and a metaverse platform service where you can experience self-expansion.

As a service where we find value in new spaces, Boppin aims to facilitate easier and more immersive connections between its users. At Boppin, we're committed to helping our users have fun and interesting experiences, and we take the necessary steps to make that happen.

### Boppin’s Values

1. Self-expansion

Boppin is a metaverse social platform where its users can share their inspiration, express and expand their egos in a fun and interesting way.

1. Users can create their own content by utilizing the unlimitedness of the metaverse in a more effective way.
2. Boppin utilizes flexible services and interactions where its users can each express and accept their individuality and conduct a unique design that emphasizes individuality. 

To experience true self-expansion, users must not be discriminated against for being different. They must be accepted as who they are, and must express themselves freely.

1. Connection

Boppin is a space where users can connect in a new and comforting way, and service where users can empathize with those with similarities and connect with different others and create new cultures. Like this, Boppin is a service that connects all users.

1. We are all connected. By being connect to others, we can help each other and live a better life.
2. Boppin provides an environment where you can instantly share your experience of self-expansion, communicate, and interact on it.
3. Boppin accepts all forms of lifestyles, no matter the gender, age, preference and style, etc.

All relationships should be made of respect and consideration, and we believe that this is the most important attitude for connection.

1. Immersiveness

Boppin aims to be an immersive platform for its users. The basics of Boppin’s art and services come from this immersiveness.

1. Boppin's design is simple and intuitive, allowing you to quickly find the information you need and minimizing the need for excessive selection and waiting.
2. Boppin organize design elements so that the distinction between images, information, and functionality is more clearly visible, making it easier for you to immerse yourself in Boppin.
3. At Boppin, we're always looking for ways to improve the interactions between our users and create a more engaging and immersive experience.

Boppin is always open to any form of feedback. Boppin will always be ready to hear your opinion, and will make timely announcements to let users know their voices are being heard.

For this, our community guidelines will be continuously revised and evolved, and we share the following information as needed for Boppin to become a platform which holds our values. 

### A Safe Space

- Boppin cares about your personal information and follows all related laws accordingly. Furthermore, we need your attention and care as well.

We can only create a safe metaverse with help from one another. Leaking or sharing others’ personal information without consent is strictly forbidden, so please be aware. Also, for your own safety, do not share personal information such as credit card information, passwords, or any other sensitive information. Please be aware that if there are any reports regarding this, the content will immediately be blocked.

- Boppin responds strictly towards all forms of criminal activities.

You must be respectful and considerate of all users. We do not accept any form of harassment such as insulting or offensive speech, hate speech, belittling of the disabled, racial discrimination, religious or political discrimination, sexism, gender hostility, etc. Boppin is a virtual space where anyone above the age of 14 may use. No one should suffer from ostracism in order to find safe encounters where you can communicate freely. Any content regarding the formerly mentioned actions or any of the sort may be deleted and the poster of the content may be banned from the service.

We restrict users from illegal actions or actions infringing copyright, trademark rights, design rights, or any other intellectual property rights. Your content belongs to you, and for this to be respected, you must respect others’ rights as well. When sharing others’ creative work, you must first ask for permission. No form of illegal activities are allowed, such as copyright infringement, fraudulent activities, sharing illegal material, and distributing malware. Also, activities such as illegal advertisements, spamming, selling or soliciting prohibited items, restricted items, illegal items for mail order, and enticing other users or sharing information or advice on dangerous actions.

We will not tolerate the distribution of illegal and obscene material, illegal sex trafficking, metaverse sex crimes, stalking, and the glorification, solicitation, and promotion of sex crimes. We especially will not tolerate the distribution child and adolescent pornographic material in any circumstance, and such actions will result in the highest level of discipline within Boppin and may result in profusion of relevant information to law enforcement authorities in accordance with applicable laws. This also applies to the exposure of pornography and other harmful information to others who are recognized as minors between the ages of 14 and 19.

Above all else, you life matters the most. We will cooperate with law enforcement to protect you against life-threatening crimes, such as promoting self-harm and suicide, soliciting and mediating murder and violence, terrorist plots, and facilitating kidnapping or abduction.

- At Boppin, we are ready to hear your feedback and we always look forward to it.

We will strive to actively interact with you, and the Help Center will be our primary point of contact. We will continue to evolve our systems to make it easier for you to report instances of Terms and Rules violations, and will have staff to take care of them quickly. Reports will be acknowledged immediately and we will take immediate action to protect you. However, not all reports will be acted upon, and sanctions will be imposed in accordance with the detailed terms and policies of the Service after verification of the report. To keep Boppin safe, we need your active input. Everyone on Boppin should be safe, and if we believe that some users are not following our guidelines, we may take appropriate action.

- [‘How to Report Things’](https://www.notion.so/How-to-Report-Things-9f73ff22b052479a82fca29813162b2e)
- List of Relevant Institutions
    - Cyber Bureau(KR) http://cyberbureau.police.go.kr/
    - Korea Suicide Prevention Center(KR) http://www.spckorea.or.kr/ / [spc@spckorea.or.kr](mailto:spc@spckorea.or.kr)
    - Ministry of Health And Welfare Call Center(KR) 129(Operating 24hr)
    - Mental Health Center Crisis Counseling(KR) 1577-199(Operating 24hr)
    - [INHOPE](https://www.inhope.org/EN) (To Report Illegal Online Content) (+31 20 257 1100)
    - [Global Modern Slavery Directory](https://www.globalmodernslavery.org/) (Trafficking Hotline Directory) (See website for regional contact info)
    - [Now Matters Now – Crisis Lines](https://www.nowmattersnow.org/help-line) (See website for regional contact info)
    - [Pathways to Safety International](https://pathwaystosafety.org/) (Support and Assistance for Americans Abroad) (833-SAFE-833)`}
    </div>
  );
});

export default CommunityGuidelines;
