import { useEffect, useRef } from 'react';

export interface UseCanvasOptions {
  onUpdate: (delta: number) => void;
  onRender: (context: CanvasRenderingContext2D) => void;
}

export function useCanvas({ onUpdate, onRender }: UseCanvasOptions) {
  const ref = useRef<HTMLCanvasElement | null>(null);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  const startTimeRef = useRef(0);

  useEffect(() => {
    startTimeRef.current = Date.now();

    const handleFrame = () => {
      frameHandle = requestAnimationFrame(handleFrame);

      if (contextRef.current === null) {
        return;
      }

      const currentTime = Date.now();
      const delta = (currentTime - startTimeRef.current) * 0.001;
      startTimeRef.current = currentTime;

      contextRef.current?.clearRect(0, 0, ref.current?.width || 0, ref.current?.height || 0)

      onUpdate(delta);
      onRender(contextRef.current);
    }

    let frameHandle = requestAnimationFrame(handleFrame);

    return () => {
      cancelAnimationFrame(frameHandle);
    }
  }, [onUpdate, onRender]);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }

    const context = ref.current.getContext('2d');
    if (context === null) {
      return;
    }

    contextRef.current = context;

    const parentNode = ref.current.parentNode as HTMLElement | null;
    if (parentNode === null) {
      return;
    }

    const handleResize = () => {
      const { width, height } = parentNode.getBoundingClientRect();
      if (ref.current === null) {
        return;
      }

      ref.current.width = width;
      ref.current.height = height;
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    parentNode.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      parentNode.removeEventListener('resize', handleResize);
    }
  }, []);

  return [ref];
}
