import React, { FC, memo } from "react";
import styles from "~/components/Home/Home.module.scss";
import Header from "../Header/Header";
import Hero from "../Hero/Hero";
import Avatar from "../Avatar/Avatar";
import Friends from "../Friends/Friends";
import MyRoom from "../MyRoom/MyRoom";
import Group from "../Group/Group";
import Footer from "../Footer/Footer";
import DownloadModal from "../DownloadModal/DownloadModal";
import { useRecoilState } from "recoil";
import downloadModalState from "../../atoms/downloadModalState";

export interface HomeProps {}

const Home: FC<HomeProps> = memo(() => {
  const [isDownloadModalOpen, setIsDownloadModalOpen] =
    useRecoilState(downloadModalState);

  return (
    <div className={styles.home}>
      <Header />
      <Hero />
      <Avatar />
      <Friends />
      <MyRoom />
      <Group />
      <Footer />
      <DownloadModal
        visible={isDownloadModalOpen}
        onClose={() => {
          setIsDownloadModalOpen(false);
        }}
      />
    </div>
  );
});

export default Home;
