import React, { FC, memo } from "react";
import styles from "./Hero.module.scss";
import { useRecoilState } from "recoil";
import downloadModalState from "../../atoms/downloadModalState";
import Tween from "../Tween/Tween";
import { useDownloadLink } from "../../hooks/useDownloadLink";

export interface HeroProps {}

const Hero: FC<HeroProps> = memo(() => {
  const [, setIsDownloadModalOpen] = useRecoilState(downloadModalState);
  const downloadLink = useDownloadLink();

  return (
    <div className={styles.hero}>
      <Tween>
        <h1 className={styles.title}>
          Let's get into the
          <br />
          joyful metaverse together!
        </h1>
      </Tween>
      <Tween>
        <a
          href={downloadLink}
          target="_blank"
          className={styles.downloadApp}
          rel="noreferrer"
        >
          Download App
        </a>
      </Tween>
    </div>
  );
});

export default Hero;
