import React, { FC, memo, useEffect, useMemo, useState } from "react";
import styles from "./Cards.module.scss";
import classnames from "classnames";

export interface CardsProps {}

const CardItem: FC<{ kind: 1 | 2 | 3 }> = ({ kind }) => {
  return (
    <div className={classnames(styles.cardItem, styles[`kind${kind}`])}></div>
  );
};

const Cards: FC<CardsProps> = memo(() => {
  const [show, setShow] = useState(0);
  const [start, setStart] = useState(0);

  const renderedCardItemWrappers = useMemo(() => {
    const nodes = [];

    for (let i = start; i < 4 + show; i++) {
      const isFirst = i === show - 1;
      const isLast = i === show + 3;

      nodes.push(
        <div
          style={{
            transform: `translateX(${i * 100 - show * 100}%)`,
            opacity: (isFirst || isLast) ? 0 : 1
          }}
          className={styles.cardItemWrapper}
          key={i}
        >
          <CardItem kind={(i % 3) + 1 as 1 | 2 | 3} />
        </div>
      );
    }

    return nodes;
  }, [show, start]);

  useEffect(() => {
    let timeout: number | null = null;
    const interval = window.setInterval(() => {
      setShow((prevState) => prevState + 1);

      timeout = window.setTimeout(() => {
        setStart((prevState) => prevState + 1);
      }, 300);
    }, 2000);

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      clearInterval(interval);
    };
  }, []);

  return <div className={styles.cards}>{renderedCardItemWrappers}</div>;
});

export default Cards;
