import Entity from "./Entity";
import Vector2 from "./Vector2";

export default class Particle extends Entity {
  position = Vector2.fromAngle(Math.random() * Math.PI * 2)
    .mul(Math.random() * 0.5)
    .add(new Vector2(0.5, 0.5));

  size = Math.random() * 0.0075 + 0.001;
  speed = Math.random() * 5 + 2;
  health = Math.random() * 100;
  alpha = 0;

  update(delta: number) {
    if (this.alpha < 0.01) {
      this.position = Vector2.fromAngle(Math.random() * Math.PI * 2)
        .mul(Math.random() * 0.5)
        .add(new Vector2(0.5, 0.5));
      this.size = Math.random() * 0.0075 + 0.001;
      this.speed = Math.random() * 5 + 1;
    }

    this.health += delta * this.speed;
    this.alpha = (Math.sin(this.health) + 1) / 2;

    const angle = this.position.angleBetween(new Vector2(0.5, 0.5));

    this.position = this.position.sub(
      Vector2.fromAngle(angle).mul(delta * 0.001)
    )
  }

  render(context: CanvasRenderingContext2D) {
    const { width, height } = context.canvas;
    const distance = this.position.distance(new Vector2(0.5, 0.5));

    context.beginPath();
    context.fillStyle = "#ffffff";
    context.globalAlpha = this.alpha;
    context.fillRect(
      this.position.x * width,
      this.position.y * height,
      width * this.size,
      width * this.size,
    );
  }
}
