import { FC, memo, useEffect, useState } from "react";
import styles from "./Header.module.scss";
import classnames from "classnames";
import { useMobileDetector } from "../../hooks/useMobileDetector";
import MobileGnb from "../MobileGnb/MobileGnb";
import { useDownloadLink } from "../../hooks/useDownloadLink";

export interface HeaderProps {}

const Header: FC<HeaderProps> = memo(() => {
  const [filled, setFilled] = useState(false);
  const downloadLink = useDownloadLink();
  const isMobile = useMobileDetector();

  useEffect(() => {
    const handleScroll = () => {
      const myRoomNode = document.getElementById("my-room");
      if (!myRoomNode) {
        return;
      }

      const myRoomNodeTop = myRoomNode.getBoundingClientRect().top;
      const isFilled = myRoomNodeTop < 0;
      setFilled(isFilled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={classnames(styles.header, {
        [styles.filled]: filled,
      })}
    >
      <div className={styles.inner}>
        <div className={styles.left}>
          <div className={styles.logo} />
          <ul className={styles.gnb}>
            <li>
              <a href="#avatar">Avatar</a>
            </li>
            <li>
              <a href="#friends">Friends</a>
            </li>
            <li>
              <a href="#my-room">My Room</a>
            </li>
          </ul>
        </div>
        <div className={styles.right}>
          <a
            href="https://www.instagram.com/boppin_global/"
            target="_blank"
            className={classnames(styles.social, styles.instagram)}
            rel="noreferrer"
          >
            instagram
          </a>
          <a
            href="https://twitter.com/Boppin_official"
            target="_blank"
            className={classnames(styles.social, styles.twitter)}
            rel="noreferrer"
          >
            twitter
          </a>
          <a
            href={downloadLink}
            target="_blank"
            className={styles.downloadApp}
            rel="noreferrer"
          >
            Download App
          </a>
        </div>
        {isMobile && <MobileGnb />}
      </div>
    </div>
  );
});

export default Header;
