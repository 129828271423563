import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import styles from "./TowerBackground.module.scss";
import { useCanvas } from "../../hooks/useCanvas";
import Entity from "../../cores/Entity";
import Particle from '../../cores/Particle';


export interface TowerBackgroundProps {}

const TowerBackground: FC<TowerBackgroundProps> = memo(() => {
  const entitiesRef = useRef<Array<Entity>>([

  ]);

  const onRender = useCallback((context: CanvasRenderingContext2D) => {
    for (let i = 0; i < entitiesRef.current.length; i++) {
      const entity = entitiesRef.current[i];
      entity.render(context);
    }
  }, []);

  const onUpdate = useCallback((delta: number) => {
    for (let i = 0; i < entitiesRef.current.length; i++) {
      const entity = entitiesRef.current[i];
      entity.update(delta);
    }
  }, []);

  const [ref] = useCanvas({
    onRender,
    onUpdate,
  });

  useEffect(() => {
    for (let i = 0; i < 150; i++) {
      const particle = new Particle();
      entitiesRef.current.push(particle);
    }

    return () => {
      entitiesRef.current = [];
    }
  }, []);

  return <canvas ref={ref} className={styles.towerBackground} />;
});

export default TowerBackground;
