import React, { FC, memo } from 'react';
import styles from './TermsAndConditions.module.scss';

export interface TermsAndConditionsProps {

}

const TermsAndConditions: FC<TermsAndConditionsProps> = memo(() => {
  return (
    <div className={styles.termsAndConditions}>
          {`Boppin Terms and Conditions 

Article 1 Purpose

This Terms and Conditions regulate the terms and conditions for members (hereinafter referred to as "Member(s)" or "You") to access and use the Company's site, service, application, product, and content related to Boppin (hereinafter referred to as the “Service(s)”) provided by WEAR Co., Ltd. (hereinafter referred to as the “Company” or “We”). The purpose of this Terms and Conditions is to set forth necessary matters, including the rights, obligations, and responsibilities between the Members and the Company.

Article 2 Definition of Terms

The definitions of the terms used in this Terms and Conditions are as follows:

Service(s): All internet services provided by the Company related to Boppin that can be accessed and used by Members via various devices such as PCs and mobile phones.
Member(s): The account of an individual, company, or organization that has agreed to this Terms and Conditions and the Company's various policies and uses the Services provided by the Company.
Password: A combination of letters, numbers, and special characters that a Member has set up to protect his/her information.
ID: A member’s internally identifiable information used to provide convenience in using the Company's Services.
Nickname: A name that a Member has set up in relation to the use of the Services, which is used as identification information in user search.
Post(s): Informational texts including picture, symbol, letter, voice, sound, image, and video, pictures, videos, files, and links posted by a Member on the Service pages provided by the Company.
Content: All digital content (network services, applications, goods, items, assets, etc.) created by the Company in digital form for Members to use.

② Except for those stipulated in Paragraph 1 of this Article, the definitions of terms used in this Terms and Conditions shall be as stipulated in the relevant laws and regulations and service-specific policies, and those not stipulated shall be subject to common commercial practices.

Article 3 Notification, Effect, and Modification of Terms and Conditions

The Company shall post the contents of this Terms and Conditions on the Service pages or their linked pages so that you can easily find them. The Company may modify this Terms and Conditions within the scope that does not violate relevant laws and regulations. In the event of the modification, the Company shall announce the modified Terms and Conditions, the reason of the modification and the effective date for at least seven (7) days on the Service pages along with the current Terms and Conditions. However, if the modified Terms and Conditions are disadvantageous to you, the Company shall announce them on the Service pages for at least thirty (30) days and provide a separate clear notice in an electronic manner such as a pop-up window asking for your consent upon log-in in order to confirm your consent to the modified Terms and Conditions.

The Company is currently providing the Boppin-related Services to you for free of charge. However, in the future, the Company may provide goods and services related to the Boppin Services for a fee, and in this case, the Company shall follow the aforementioned procedure and method when modifying the Terms and Conditions.

If you do not explicitly express your refusal despite the Company's announcement of the modified Terms and Conditions, the Company will presume your consent to the modified Terms and Conditions. If you do not agree to the modified Terms and Conditions, the Company cannot apply them and you may terminate the Service Agreement. However, if there are special circumstances where the existing Terms and Conditions cannot be applied, the Company may terminate the Service Agreement with you. Even if a part of this Terms and Conditions is concluded to be inapplicable, the remaining parts shall continue to be effective.

Article 4 Additional Rules

The Company may establish separate terms and conditions and activity policies (hereinafter referred to as "Activity Policies") for individual Services. This Terms and Conditions and the Activity Policies are supplementary, and any matters or interpretations not stipulated in this Terms and Conditions shall be governed by separate Activity Policies, relevant laws and regulations, or common commercial practices.

Article 5 Execution and Application of Service Agreement

① The Boppin-related services provided by the Company are only available to Members who are 14 years of age or older. Members who are 14 years of age or older but under 19 years of age are considered to have obtained the consent from their parents or legal representatives for using the Services or registering an account to the Services.  If they are found to not have obtained the consent from their parents or legal representatives, their use of the Services may be restricted or membership may be canceled.
② The Service Agreement is executed when the Company accepts the application for registration from an applicant for the registration (hereinafter referred to as the "Applicant") who agrees to this Terms and Conditions.
③ Any application shall be approved in principle, provided that the Company may refuse to approve an application or terminate the Service Agreement after the registration if the application falls under any of the following subparagraphs:
If the applicant provides false information in the application or does not satisfy the requirements for application;
If the applicant attempts to use the Service in a country where the Company does not provide the Service through abnormal or circumventive means;
If a user under the age of 14 attempts to use the Service;
If the applicant applies for registration with the intention of harming social safety and order or public morals;
If the applicant intends to use the Service for improper purposes;
If the Company decides that approval is inappropriate due to any other similar causes that fall under each subparagraph.
④ The Company may withhold approval in each of the following cases until the cause is resolved:
If the Company does not have sufficient capacity, is unable to support specific mobile devices, or has technical problems;
If there is a malfunction in the Service or Service fee payment systems;
If the Company decides that approval of the application is difficult due to any other similar causes that fall under each subparagraph.

Article 6 Modification of Personal Information

You can access and modify your personal information on the personal information modification page of the Service.

If there is a change in the information provided at the time of registration, you must modify it on the personal information modification page. If it is difficult to use the page, you may use the inquiry pages to request the Company to modify the information.

The Company may prevent you from using a nickname that falls under each subparagraph in accordance with a specific procedure set forth for each subparagraph:

If the nickname may infringe on your privacy;
If the nickname may cause disgust or violate public morals;
If the nickname is already being used by another Member, making it difficult to provide the Service;
If there are other reasonable causes.

Article 7 Protection and Management of Personal Information

The Company may collect and use the personal information necessary for the provision of the Services in accordance with the Privacy Policy.

The protection and management of your personal information is governed by relevant laws and regulations and the Company’s Privacy Policy.


The Company’s Privacy Policy does not apply to linked websites other than the Company’s official website (the websites not operated by the Company such as the websites operated by a requester for mail order brokerage, etc.).


The Company shall conduct the work of processing and management of personal information in principle, but it may outsource all or part of the work to another company that it appointed, and in such case, it shall announce the outsourcing in the Privacy Policy. 


If you wish to withdraw the consent for the use or provision of the personal information you provided in the course of executing the Service Agreement, you shall follow the procedure specified in the Privacy Policy. When you withdraw such consent, the Service Agreement will be automatically terminated.

The Company is not responsible for any personal information which is exposed due to causes attributable to you, so please take extra caution.

Article 8 Provision of the Service

The Company shall provide the Service 24 hours a day, 365 days a year, in principle. However, the Company may restrict or suspend all or part of the Service in each of the following cases:

Cases of regular or temporary inspections for server expansion, maintenance, or repair;
Cases where normal use of the Service is interrupted by replacement or failure of equipment, or traffic surge;
Cases where the Company cannot maintain the Service due to its own circumstances;
Cases where there are force majeure circumstances such as natural disasters or national emergencies.
In the cases of the preceding paragraph, the Company shall give you a notice, and unless otherwise provided in this Terms and Conditions, such notice may be made by means of a notice message in the Service or any other appropriate means deemed appropriate by the Company. However, in the event that there are unavoidable reasons that the Company cannot give prior notice, the Company may give notice afterwards.

③ The Company may conduct inspections if necessary for the provision of the Service, and the required inspection period shall be separately notified in the Service notice.

The Company may separate the Service into several parts and specify different available hours for each part, and in such case, shall give notice.

The Company may establish different terms and conditions for each service of the Service, and you will be required to agree to each terms and conditions when using each service for the first time.

For opinions or complaints that you raised regarding the use of the Service, the Company shall inform you of the status and results through the Customer Center or any other means deemed appropriate by the Company.

Article 9 Changes to the Service

The Company may change all or part of the Service provided, as necessary for operational or technical reasons. Unless otherwise provided in this Terms and Conditions, the Company may notify you of the change by means of a notice message in the Service or any other means deemed appropriate by the Company.

The Company shall post the content and effective date of the Service changes on the notice in the Service and give you notice.

The Company may modify, suspend, or terminate all or part of the Service in accordance with its policies or as necessary for its management, and unless otherwise provided for in the relevant laws and regulations, the Company shall not compensate you for such modification, suspension, or termination. 

Article 10. Provision of Information and Advertising

The Company may display in the Service pages or send out via email, SMS, push notifications, etc. various information in the course of operating the Service. However, you may refuse to receive any of such information at any time, except for the transaction-related information which shall be provided in accordance with related laws and regulations and the replies to your inquiries.

The Company may display in the Service Pages or send out via email, SMS, push notifications, etc. advertisements in the course of operating the Service. However, the advertisements via email shall be transmitted only with your prior consent to reception and you may refuse to receive them at any time.

The Company may display in the Service pages, bulletin boards, homepages, etc. advertisements in the course of operating the Service. You shall not take any measures, such as changing, modifying, or restricting the posts or other information related to the Service provided by the Company.

If you use advertisements posted in the Service pages or participate in promotional activities of advertisers through the Service, communication or transaction between you and advertisers falls entirely under the responsibility of you and advertisers. If a problem occurs between you and advertisers, you and advertisers must resolve it directly, and the Company shall not be liable unless there is willful misconduct or gross negligence by the Company.

The Company may use your personal information to fulfill its legal obligations under this Article.

Article 11. Copyright of Posted Content

① The copyright of the content such as posts, comments, and tags (hereinafter referred to as the "Post(s)") created by Members and posted on the Service pages belongs to the Member who created it, and Members allow the Company to use the Post by modifying its edited format or otherwise transforming it in any way (including publicizing, reproducing, performing, transmitting, distributing, broadcasting, creating derivative works, etc., without restriction on the period or region).

② The Posts that can be accessed by others are considered public and not exclusive. You shall not upload, post, or transmit any content that you consider confidential onto or through the Service.

③ Copyrights and other intellectual property rights of the Service and contents created by the Company belong to the Company, except for the copyrighted materials provided according to affiliation agreements and the Posts created by Members. In accordance with relevant laws and regulations, the Company has ownership and license of copyrights and other intellectual property rights of all trademarks, service marks, logos, etc. related to the Service provided by the Company, such as the design of the Service, text, scripts, graphics, and intracommunication systems created by the Company. 

④ Members are granted by the Company only the right to use the Service in accordance with the terms of use set forth by the Company, and Members cannot dispose of the Service including transferring, selling, providing as collateral. In other words, this Terms and Conditions grants Members permission to use the Service, not to own the Service or the copyright thereof.

⑤ You shall not use or let others use for commercial purposes any materials of which copyright and other intellectual property right belong to the Company or any third party by means of copying, transmitting, etc. (including editing, publicizing, performing, distributing, broadcasting, creating derivative works) without prior consent of the Company or the third party.

⑥ Unless explicitly permitted, you shall not use, copy, or distribute for commercial purposes the member status information obtained in the Service. Unless explicitly permitted in writing by the Company, you shall not attempt to create derivative works, decompile, or extract the source code related to the Service or the software included therein.

⑦ If your Post violates the copyright, damages the reputation, or interferes with the business of another person, it falls entirely under the responsibility of you who posted the content.

⑧ If a third party claims for damages or other demand against the Company related to the Posts, you shall hold harmless the Company and compensate for the damages suffered by the Company.  

Article 12 Management of the Posts

In the event that your Post contains matters that violate relevant laws and regulations, such as the "Act on Promotion of Information and Communications Network Utilization and Information Protection" (hereinafter referred to as the "Information and Communications Network Act") and the "Copyright Act", including but not limited to the following, the Company may suspend the posting of or delete the Post without prior notice and shall not be held responsible for such action:

If the Post defames or damages the reputation of the Company, other members, or third parties;
If the Post violates public order and morals;
If the Post is judged to be associated with criminal acts;
If the Post infringes on the copyright and other rights held by the Company or third parties;
If the Post creates political or religious disputes in a manner that violates laws and regulations or infringes on the rights of others;
If the Post promotes illegal websites or contains obscene materials;
If the Post steals others’ personal information or forges/manipulates information entered by others without permission;
If the Post is against the purpose of posting by providing unauthorized advertisements or repeatedly providing the same contents;
If the Post violates other relevant laws and regulations or the Company's policies.

In addition to the cases listed in the preceding paragraph, the Company may take temporary measures against the Post in accordance with the Information and Communications Network Act if there are circumstances in which infringement of rights can be recognized or the Post violates the Company's policies and relevant laws and regulations.


Article 13 Obligations of the Company

① The Company shall not engage in activities that violate this Terms and Conditions, relevant laws and regulations, or public morals, and shall make its best efforts to provide the Service in a stable manner.

② The Company shall establish and operate a security system for personal information protection to help you use the Service safely, and shall announce and comply with the Privacy Policy.

Article 14 Obligations of Members

① You shall comply with relevant laws and regulations, this Terms and Conditions, Service Activity Policies, cautionary notices announced on the Service pages, and other notices provided by the Company regarding the use of the Service, and shall not engage in activities that interfere with the Company's businesses.
② You shall not transfer or give your right to use the Service to others, let others to use it, or provide it as collateral without the explicit consent of the Company.
③ You shall not engage in the each of the following activities while using the Service:
Providing false information when applying for or modifying Service registration, or using someone else's personal information;
Using the Post or information acquired in the course of using the Service for commercial purposes or disclosing them to third parties through publishing or broadcasting, except when necessary for public purposes with the Company's prior consent.
Conducting commercial activities such as selling goods or services through the Service (including hacking, making profit from advertising, commercial activities through pornographic sites, illegal distribution of commercial software, etc.), except when the Company officially recognizes such commercial activities.
Interfering with the Company’s operation of the Service or other Members' use of the Service, or impersonating the Company's management, employees, or those concerned.
Posting materials that fall under the categories listed in Article 12, Paragraph 1 of this Terms and Conditions.
④ If you engage in any of the activities listed in the preceding paragraph, the Company may delete the relevant Post without prior notice, restrict the use of the Service, or unilaterally terminate the Service Agreement. The Company shall notify you of such restriction of use or termination of the Service Agreement.

Article 15 Notice to Members

The Company may notify Members of matters related to their rights and obligations regarding the use of the Service via email designated by the Member.

Notification to all Members may be made by announcement on the Company's Service notices for seven () days or more, instead of the notification in Article 15, Paragraph 1.

Article 16 Restriction of Use and Termination of the Service Agreement

If you wish to terminate the Service Agreement, you may request withdrawal at any time by using the withdrawal function provided within the Service, and the Company shall process it immediately unless there are unavoidable circumstances at the time of the request.

If you terminate the Service Agreement, all Posts you posted on the Service pages will be deleted. However, if Posts were posted by a third party as clippings or via other sharing functions, or they were posted as part of a comment to a post by a third party, the Posts and comments will not be deleted. Therefore, it is recommended to delete them before requesting withdrawal.

If you violate the obligations under this Terms and Conditions or interfere with normal operations, the Company may restrict the use of the Service or terminate the Service Agreement in stages, such as warnings, temporary suspensions, or permanent suspensions.

Notwithstanding the measures in the preceding paragraph, the Company may immediately impose a permanent suspension if you violate relevant laws and regulations, such as payment fraud, illegal provision of illegal programs that violate the Copyright Act, interference with business operations, illegal communication and hacking that violate the Information and Communication Network Act, and distribution of malicious programs. In case of the permanent suspension in accordance with this Paragraph, all items and benefits obtained in the use of the Service will cease to exist, and the company will not compensate for them.

If you disagree with the restriction of use related to the suspension of use under Article 16, Paragraph 3, you may file an objection in accordance with the procedure set by the Company, and the Company shall resume the use of the Service immediately if your objection is deemed reasonable.

When restricting the use of the Service or terminating the Service Agreement in accordance with Article 16, the Company shall give notice in accordance with Article 15 (Notice to Members).

If a member has not logged in for one (1) year, the Company may restrict the use of the Service to protect personal information and promote operational efficiency.

The Company is against any and all activities distributing children and youth pornographies and does not allow them in any form:  

Children and youth pornographies include not only general pornography but also threats to share confidential images and solicitations for sexual services or activities. Even if a material is shared for the purpose of raising awareness of sexual crimes related to children and youth, the material may be included in the same category if it clearly includes explicit pornography; 
If the Company finds a pornographic content where the subject is identifiable as a minor, it will take immediate measures to the content. In addition, it will take the strongest possible disciplinary measure against those who posted or shared the content and may proceed to take actions in cooperation with judicial authorities such as providing related information to investigation authorities; 
The Company is always ready to receive your report of any pornographies related to children and youth. We will make our best effort to protect minors from such threats.

Article 17 Liabilities

The Company shall be liable for damages suffered by you only to the extent where such damages are caused by the Company's willful misconduct or gross negligence. The Company shall not be liable for your damages that occurred without its willful misconduct or gross negligence. Additionally, the Company shall not be liable for indirect damages, special damages, consequential damages, punitive damages, and disciplinary damages to the extent allowed by relevant laws and regulations.

If the Company suffers damages or a third party claims for damages or other demand against the Company due to your misconduct or violation of this Terms and Conditions in using the Service, you shall hold harmless the Company under your sole responsibility and at your expense. If the Company is not indemnified, you shall be liable for all damages suffered by the Company as a result of the failure of indemnification.

Article 18 Indemnification

The Company shall be indemnified from liability for the provision of the Service if it is unable to provide the Service due to natural disasters or other similar force majeure circumstances, such as the revision or enactment of relevant laws and regulations, or government regulations.

The Company shall not be liable for any Service interruptions caused by your own fault.

Without its willful misconduct or gross negligence, the Company shall not be liable for your failure to realize expected gains from the Service provided by the Company or any damages caused by your choice or use of Posts on the Service pages. Additionally, the Company shall not be liable for the reliability, accuracy, or contents of the Post that you posted on the Service pages.

The Company shall have no obligation to intervene in disputes between Members or between a Member and a third party arising from the use of the Service, and shall not be liable for any damages resulting from such disputes without its willful misconduct or gross negligence. 

Article 19 Jurisdiction and Governing Law

① This Terms and Conditions and the Services provided by the Company(including the Privacy Policy) shall be governed and interpreted in accordance with the laws of the Republic of Korea. In the event any lawsuit is filed between a Member and the Company, the laws of the Republic of Korea shall govern.

② In the event of any lawsuit arising from disputes between a Member and the Company, the court having jurisdiction over the Member's address shall be the competent court. If the Member's address is not known, the competent court shall be determined based on the Member's place of residence. If the Member's address or place of residence is unclear, the dispute shall be resolved by a court decided in accordance with the Korean Civil Procedure Act.

③ In the case of a Member with addresses or places of residence overseas, any lawsuits arising from disputes between the Company and the Member shall be exclusively under the jurisdiction of the Seoul Central District Court in the Republic of Korea, which shall assume exclusive jurisdiction as first instance court, notwithstanding the provisions of the preceding paragraph.

Addendum

This Terms and Conditions is effective as of April 21, 2023.`}
    </div>
  );
});

export default TermsAndConditions;
