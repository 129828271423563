import React, { FC, memo } from "react";
import styles from "./MyRoom.module.scss";
import Section from "../Section/Section";
import Tower from "../Tower/Tower";

export interface MyRoomProps {}

const MyRoom: FC<MyRoomProps> = memo(() => {
  return (
    <div id="my-room" className={styles.myRoom}>
      <div className={styles.inner}>
        <Section
          className={styles.section}
          theme="white"
          category="My Room"
          title={`Where you are,\nis who you are`}
          description={`Which interior do you prefer?\nInvite people, have fun, and bop together!`}
        />
        <Tower />
      </div>
    </div>
  );
});

export default MyRoom;
