import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from "./EasterEgg.module.scss";
import Entity from "../../cores/Entity";
import { useCanvas } from "../../hooks/useCanvas";
import EasterEggText from "../../cores/EasterEggText";

export interface EasterEggProps {}

const EasterEgg: FC<EasterEggProps> = memo(() => {
  const [count, setCount] = useState(0);
  const entitiesRef = useRef<Array<Entity>>([]);

  const onRender = useCallback((context: CanvasRenderingContext2D) => {
    for (let i = 0; i < entitiesRef.current.length; i++) {
      const entity = entitiesRef.current[i];
      entity.render(context);
    }
  }, []);

  const onUpdate = useCallback((delta: number) => {
    for (let i = 0; i < entitiesRef.current.length; i++) {
      const entity = entitiesRef.current[i];
      entity.update(delta);
    }
  }, []);

  const [ref] = useCanvas({
    onRender,
    onUpdate,
  });

  useEffect(() => {
    if (count >= 10) {
      setCount(0);
      for (let i = 0; i < 10; i++) {
        const easterEggText = new EasterEggText();
        entitiesRef.current.push(easterEggText);
      }
    }
  }, [count]);

  return (
    <div className={styles.logo} onClick={() => {
      setCount(prevState => prevState + 1);
    }}>
      <div className={styles.canvas}>
        <canvas ref={ref} />
      </div>
    </div>
  );
});

export default EasterEgg;
