import React, { FC, memo } from "react";
import styles from "./Group.module.scss";
import { useRecoilState } from "recoil";
import downloadModalState from "../../atoms/downloadModalState";
import Tween from "../Tween/Tween";
import { useInView } from "@react-spring/web";
import { useDownloadLink } from '../../hooks/useDownloadLink';

export interface GroupProps {}

const Group: FC<GroupProps> = memo(() => {
  const [, setIsDownloadModalOpen] = useRecoilState(downloadModalState);
  const [ref, inView] = useInView({
    once: true,
    amount: 0.8,
  });

  const downloadLink = useDownloadLink();

  return (
    <div className={styles.group} ref={ref}>
      <Tween visible={inView}>
        <h1 className={styles.title}>
          Be the TRENDY now,
          <br />
          in the joyful metaverse
        </h1>
      </Tween>
      <Tween visible={inView}>
        <a
          href={downloadLink}
          target="_blank"
          className={styles.downloadApp}
        >
          Download App
        </a>
      </Tween>
    </div>
  );
});

export default Group;
