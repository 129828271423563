import React, { FC, memo, useMemo } from "react";
import styles from "./Footer.module.scss";
import classnames from "classnames";
import { useMobileDetector } from "../../hooks/useMobileDetector";
import EasterEgg from "../EasterEgg/EasterEgg";
import { Link } from "react-router-dom";

export interface FooterProps {}

const PRIVACY_POLICY_LINK = "#";
const TERMS_AND_CONDITIONS_LINK = "#";
const ACTIVITY_POLICY_LINK = "#";
const SUPPORT_LINK = "#";

const Footer: FC<FooterProps> = memo(() => {
  const isMobile = useMobileDetector();

  const renderedOtherLinks = useMemo(() => {
    if (isMobile) {
      return (
        <div className={styles.otherLinks}>
          <div>
            <a href={PRIVACY_POLICY_LINK}>Privacy policy</a>
            <a href={TERMS_AND_CONDITIONS_LINK}>Terms and conditions</a>
          </div>
          <div>
            <a href={ACTIVITY_POLICY_LINK}>Activity policy</a>
            <a href={SUPPORT_LINK}>Support</a>
          </div>
        </div>
      );
    }

    return (
      <ul className={styles.otherLinks}>
        <li>
          <a href={PRIVACY_POLICY_LINK}>Privacy policy</a>
        </li>
        <li>
          <a href={TERMS_AND_CONDITIONS_LINK}>Terms and conditions</a>
        </li>
        <li>
          <a href={ACTIVITY_POLICY_LINK}>Activity policy</a>
        </li>
        <li>
          <a href={SUPPORT_LINK}>Support</a>
        </li>
      </ul>
    );
  }, [isMobile]);

  const renderedRightNode = useMemo(() => {
    return (
      <div className={styles.right}>
        <div>{renderedOtherLinks}</div>
        <div className={styles.socialLinks}>
          <a
            href="https://www.instagram.com/boppin_global/"
            target="_blank"
            className={classnames(styles.social, styles.instagram)}
            rel="noreferrer"
          >
            instagram
          </a>
          <a
            href="https://twitter.com/Boppin_official"
            target="_blank"
            className={classnames(styles.social, styles.twitter)}
            rel="noreferrer"
          >
            twitter
          </a>
        </div>
      </div>
    );
  }, [renderedOtherLinks]);

  const renderedSocialLinks = useMemo(() => {
    return (
      <div className={styles.storeLinks}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className={classnames(styles.storeLink, styles.google)} href="#">
          Google Play
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className={classnames(styles.storeLink, styles.apple)} href="#">
          App Store
        </a>
      </div>
    );
  }, []);

  return (
    <div className={styles.footer}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <EasterEgg />
          {isMobile ? renderedRightNode : null}
          {isMobile ? renderedSocialLinks : null}
          <p className={styles.links}>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-and-conditions">Terms and conditions</Link>
            <Link to="/community-guidelines">Community Guidelines</Link>
          </p>
          <p className={styles.copyright}>
            © 2023 WE-AR Inc. All rights reserved.
          </p>
          {isMobile ? null : renderedSocialLinks}
        </div>
        {isMobile ? null : renderedRightNode}
      </div>
    </div>
  );
});

export default Footer;
