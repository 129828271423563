import React, { FC, memo, useEffect, useMemo, useState } from "react";
import styles from "./Tower.module.scss";
import classnames from "classnames";
import TowerBackground from "../TowerBackground/TowerBackground";

export interface TowerProps {}

const Tower: FC<TowerProps> = memo(() => {
  const [selectedShow, setSelectedShow] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedShow((prevState) => (prevState + 1) % 4);
    }, 1000);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  const renderedShows = useMemo(() => {
    const shows = [];

    for (let i = 0; i < 4; i++) {
      shows.push(
        <div
          key={i}
          className={classnames(styles.show, {
            [styles.isSelected]: i === selectedShow,
          })}
        >
          <div className={styles.cat} />
        </div>
      );
    }

    return shows;
  }, [selectedShow]);

  return (
    <div className={styles.tower}>
      <TowerBackground />
      {renderedShows}
    </div>
  );
});

export default Tower;
