import React, { FC, memo } from "react";
import styles from "./Avatar.module.scss";
import Section from "../Section/Section";
import Cards from "../Cards/Cards";

export interface AvatarProps {}

const Avatar: FC<AvatarProps> = memo(() => {
  return (
    <div id="avatar" className={styles.avatar}>
      <div className={styles.inner}>
        <Section
          className={styles.section}
          category="Avatar"
          title={`Have your\ntrendy avatar!`}
          description={`Faster than anyone\nLeading the trend with my #OOTD`}
        />
        <div className={styles.cards}>
          <Cards />
        </div>
      </div>
    </div>
  );
});

export default Avatar;
