import React, { FC, memo } from "react";
import styles from "./Friends.module.scss";
import Section from "../Section/Section";

export interface FriendsProps {}

const Friends: FC<FriendsProps> = memo(() => {
  return (
    <div id="friends" className={styles.friends}>
      <div className={styles.inner}>
        <Section
          className={styles.section}
          category="Friends"
          title="Come play!"
          description={`No time for boredom\nBop in to meet new friends every day`}
        />
        <div className={styles.banner} />
      </div>
    </div>
  );
});

export default Friends;
