import { useMemo } from 'react';

export function useDownloadLink() {
  const query = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);

  const gl = query.get('gl');

  return `https://play.google.com/store/apps/details?id=app.boppin&hl=en${gl ? `&gl=${gl}` : ''}`;
}
